import React, { useEffect, useState } from 'react';

interface ChatStatus {
   unreadMessagesCount?: number;
   unreadZendeskMessagesCount?: number;
   unreadMessagesSource?: string;
   chatIsFocused?: boolean;
}

const ChatContext = React.createContext<{
   chatStatus: ChatStatus | null;
   updateChatStatus: React.Dispatch<React.SetStateAction<ChatStatus | null>>;
   isSendbirdConversationVisible: boolean;
   setIsSendbirdConversationVisible: (prop: boolean) => void;
   isZendeskReady: boolean;
   setIsZendeskReady: (prop: boolean) => void;
   isZendeskConversationVisible: boolean;
   setIsZendeskConversationVisible: (prop: boolean) => void;
}>({
   chatStatus: null,
   updateChatStatus: () => {
      return;
   },
   isSendbirdConversationVisible: false,
   setIsSendbirdConversationVisible: () => {
      return;
   },
   isZendeskReady: false,
   setIsZendeskReady: () => {
      return;
   },
   isZendeskConversationVisible: false,
   setIsZendeskConversationVisible: () => {
      return;
   },
});

export const ChatProvider = ({ children }: { children: React.ReactNode }) => {
   const [chatStatus, updateChatStatus] = React.useState<ChatStatus | null>(
      null,
   );
   const [isSendbirdConversationVisible, setIsSendbirdConversationVisible] =
      useState(false);
   const [isZendeskReady, setIsZendeskReady] = useState(false);
   const [isZendeskConversationVisible, setIsZendeskConversationVisible] =
      useState(false);

   useEffect(() => {
      if (isSendbirdConversationVisible) {
         document.body.classList.add('is-sendbird-opened');
      } else {
         document.body.classList.remove('is-sendbird-opened');
      }

      return () => {
         document.body.classList.remove('is-sendbird-opened');
      };
   }, [isSendbirdConversationVisible]);

   useEffect(() => {
      if (isZendeskConversationVisible) {
         document.body.classList.add('is-zendesk-opened');
      } else {
         document.body.classList.remove('is-zendesk-opened');
      }

      return () => {
         document.body.classList.remove('is-zendesk-opened');
      };
   }, [isZendeskConversationVisible]);

   return (
      <ChatContext.Provider
         value={{
            chatStatus,
            updateChatStatus,
            isSendbirdConversationVisible,
            setIsSendbirdConversationVisible,
            isZendeskConversationVisible,
            setIsZendeskConversationVisible,
            isZendeskReady,
            setIsZendeskReady,
         }}
      >
         {children}
      </ChatContext.Provider>
   );
};

export const useChat = () => {
   const context = React.useContext(ChatContext);

   if (context === undefined) {
      throw new Error('useChat must be used within a Provider');
   }
   return context;
};
