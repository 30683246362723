import { Route } from 'next';

import { IconName } from '@/components/Icon';

export interface NavIconMeta {
   isChat: boolean;
}

export interface NavItem {
   label: string;
   link: Route;
   icon: IconName;
   meta?: NavIconMeta;
}

export interface IAccountItem {
   label: string;
   link: string;
   icon: JSX.Element | JSX.Element[];
   newTab?: boolean;
}

export const NavItems: NavItem[] = [
   {
      label: 'Home',
      link: '/',
      icon: 'sun',
   },
   {
      label: 'My Journey',
      link: '/journey',
      icon: 'journey',
   },
   {
      label: 'Explore',
      link: '/explore',
      icon: 'explore',
   },
   {
      label: 'Support',
      link: '/support',
      icon: 'chat',
      meta: {
         isChat: true,
      },
   },
   {
      label: 'Account',
      link: '/account',
      icon: 'account',
   },
];

export const LogoutItem: NavItem = {
   label: 'Logout',
   link: '/auth/signout',
   icon: 'logout',
};
