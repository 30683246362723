'use client';

import { BackgroundProps, Box, Flex, FlexProps } from '@chakra-ui/react';
import { Route } from 'next';
import { usePathname } from 'next/navigation';
import React, { ReactNode, useEffect, useMemo } from 'react';

import { useChatBootstrap } from '@/hooks/react-query/useChatBootstrap';

import { BackgroundNoisePattern } from '@/components/BackgroundNoisePattern';
import { InnerwellLogo } from '@/components/Images';
import { NavItemLink } from '@/components/NavItemLink';

import { useChat } from '@/contexts/chat-context';
import { LogoutItem } from '@/utils/navItems';

interface IProps extends FlexProps {
   contentBg?: BackgroundProps['bg'];
   withBgNoise?: boolean;
   sidebarContent?: ReactNode;
}

const hideMobileNavPaths: Route[] = [
   '/ads/form',
   '/ads/set-intentions/form',
   '/ads/set-intentions/intro',
   '/ads/set-intentions/completed',
   '/weekly-pause',
];

const SideNavLayout: React.FC<IProps> = ({
   sidebarContent,
   children,
   contentBg = 'background.primary',
   withBgNoise,
   ...rest
}) => {
   const { updateChatStatus } = useChat();

   const pathname = usePathname();

   const isMobileNavHidden = useMemo(() => {
      // This is probably a bug
      return hideMobileNavPaths.includes(pathname as Route);
   }, [pathname]);

   const { data } = useChatBootstrap();

   useEffect(() => {
      if (data) {
         updateChatStatus((prev) => {
            return {
               ...prev,
               unreadMessagesCount:
                  data.channels.care_team.unread_message_count,
            };
         });
      }
   }, [data, updateChatStatus]);

   return (
      <Flex h="100%" minH="var(--app-height)" zIndex={999}>
         <Flex
            as="nav"
            w={{ base: '100%', lg: '260px' }}
            position={{ base: 'fixed', lg: 'sticky' }}
            maxH={{ base: 'none', lg: 'var(--app-height)' }}
            minH={{ base: 'auto', lg: 'full' }}
            top={{ base: 'auto', lg: 0 }}
            left={0}
            bottom={{ base: 0, lg: 'auto' }}
            flexDir="column"
            bg="background.tertiary"
            zIndex={100}
            px={{ base: 0, lg: 7 }}
            id="main-nav"
            flexShrink={0}
            display={{
               base: isMobileNavHidden ? 'none' : 'flex',
               lg: 'flex',
            }}
            {...rest}
         >
            <InnerwellLogo
               display={{ base: 'none', lg: 'block' }}
               fill="white"
               mt={5}
               ml={3}
               width={120}
               height={20}
               mb={{ base: 0, xl: 'max(13vh, 135px)' }}
            />

            <Flex
               direction={{ base: 'row', lg: 'column' }}
               justifyContent={{
                  base: 'space-between',
                  md: 'center',
                  xl: 'flex-start',
               }}
               gap={{ base: 0, lg: 1 }}
               flex={{ base: 1, lg: 1 }}
            >
               {sidebarContent}
            </Flex>

            <Box display={{ base: 'none', lg: 'block' }} height={100} pt={15}>
               <NavItemLink {...LogoutItem} />
            </Box>
         </Flex>

         <Box
            flex={1}
            position="relative"
            padding={0}
            margin={0}
            px={{
               base: 4,
               lg: 10,
            }}
            color="white"
            bg={contentBg}
            minH="full"
            zIndex={99}
         >
            <Box>
               {withBgNoise ? (
                  <BackgroundNoisePattern w="full" minH="full" />
               ) : null}

               {children}
            </Box>
         </Box>
      </Flex>
   );
};

export default SideNavLayout;
