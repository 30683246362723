import { Box, BoxProps } from '@chakra-ui/react';

export const BackgroundNoisePattern = (props: BoxProps) => {
   return (
      <Box
         backgroundImage="/images/bg-noise-pattern.png"
         pointerEvents="none"
         w="100%"
         h="100%"
         backgroundRepeat="repeat"
         pos="absolute"
         left={0}
         top={0}
         backgroundSize="100px 100px"
         {...props}
      />
   );
};
